import * as React from "react"
import { Link, graphql } from "gatsby"
import Bio from "../components/bio"
import ListHeader from "../components/listHeader"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import "../style.css"

const ProjectPosts = ({ data, location }) => {
  const projects = data.allMdx.nodes
  const {
    allMdx: {
      nodes: { excerpt },
    },
  } = data
  const catalog = "Projects"
  if (projects.length === 0) {
    return (
      <Layout location={location}>
        <Seo title="All projects" />
        <ListHeader catalog={catalog} />
        <p>No projects found.</p>
      </Layout>
    )
  }

  return (
    <Layout location={location}>
      <Seo title="All projects" />
      <ListHeader catalog={catalog} />
      <ol style={{ listStyle: `none` }}>
        {projects.map(post => {
          const title = post.frontmatter.title || post.slug
          const description = post.frontmatter.description || excerpt
          return (
            <li key={post.slug}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <Header>
                  <h2>
                    <Link
                      to={post.slug}
                      itemProp="url"
                      className="index-list-link"
                    >
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h2>
                  <PostDate>{post.frontmatter.date}</PostDate>
                </Header>
                <section>
                  <p>{description}</p>
                </section>
              </article>
            </li>
          )
        })}
      </ol>
      <footer>
        <Bio />
      </footer>
    </Layout>
  )
}

export default ProjectPosts

export const projectsQuery = graphql`
  query {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { eq: "project" }, date: {} } }
      limit: 1000
    ) {
      nodes {
        id
        excerpt
        frontmatter {
          title
          category
          description
          date(formatString: "MMM Do, YYYY")
          tags
        }
        slug
      }
    }
  }
`
const Header = styled.header`
  h2 {
    font-size: var(--fontSize-2);
    line-height: var(--lineHeight-relaxed);
    color: var(--color-component-green);
  }
  a:hover,
  a:focus {
    text-decoration: none;
    color: var(--color-component-blue);
  }
`
const PostDate = styled.small`
  color: var(--color-heading);
`
